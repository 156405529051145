<template>
  <div class="mx-2">
    <b-row class="m-0 main-section mb-1">
      <b-col cols="12">
        <div
          class="d-block d-md-flex justify-content-between align-items-center"
        >
          <div class="mb-2 m-md-0">
            <h2>Recommendation Packages</h2>
            <p class="mb-0">Your listed packages</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="m-0">
      <b-col cols="12" class="tab-card">
        <b-row class="m-0">
          <b-col
            sm="12"
            md="6"
            v-for="(tabValue, index) in packages"
            :key="index"
            >
            <div>
              <b-card class="p-0">
                <b-card-header class="h1 py-2 d-flex pt-0">
                  <b-card-title
                    class="font-weight-bolder h1 d-flex align-item-center justify-content-between w-100"
                  >
                    <div class="d-flex">
                      {{ tabValue.title }}
                      <b-badge class="ml-1 case-badge">{{
                        tabValue.segment
                      }}</b-badge>
                    </div>
                    <feather-icon
                      @click="copyLink(tabValue.permaLink)"
                      class="cursor-pointer"
                      icon="Link2Icon"
                      size="20"
                    />
                  </b-card-title>
                  <div class="d-flex align-items-center"></div>
                </b-card-header>
                <b-card-body class="p-0">
                  <div class="d-flex mb-1">
                    <div class="mr-3">
                      <p class="font-weight-bolder mb-0 font-base">
                        Risk level
                      </p>
                      <span>{{ tabValue.riskProfile }}</span>
                    </div>
                    <div class="">
                      <p class="font-weight-bolder mb-0 font-base">
                        Minimum capital required
                      </p>
                      <span>{{ tabValue.minCapital }} INR</span>
                    </div>
                  </div>
                  <div class="mb-1">
                    <p class="font-weight-bolder mb-0 font-base">
                      Discription
                    </p>
                    <span>{{ tabValue.description }}</span>
                  </div>
                  <div class="mb-1">
                    <p class="font-weight-bolder mb-0 font-base">Details</p>
                    <p class="mb-0">{{ tabValue.details }}</p>
                  </div>
                  <div>
                    <b-row>
                      <!-- sm="4" -->
                      <b-col
                        md="3"
                        class="mb-1 col-6"
                        v-for="(option, index) in tabValue.payOptions"
                        :key="index"
                      >
                        <p class="font-weight-bolder mb-0 font-base"> {{ option.title.replace("_", " ") }}</p>
                        <span class="price d-block">₹ {{ option.price }}</span>
                        <a variant="primary" class="btn btn-primary pill mt-1" :href="option.url" >Pay</a>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
  
<script>
import { ref, onMounted, inject } from "@vue/composition-api";
import InputField from "@/views/components/form/InputField.vue";
import SelectField from "@/views/components/form/SelectField.vue";
import DateField from "@/views/components/form/DateField.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import router from '@/router'

/* eslint-disable global-require */
export default {
  components: {
    SelectField,
    InputField,
    DateField,
  },
  setup() {
    const $http = inject("$http");
    const tab = ref("active");
    const tabs = [
      { id: 1, title: "active" },
      { id: 2, title: "suspended" },
    ];

    let packages = ref([]);

    onMounted(() => {
      getPackage();
    });

    const getPackage = async () => {
      await $http
        .get(`advisor/${router.currentRoute.params.advisorID}/packages`)
        .then((response) => {
          packages.value = response.data.packages;
        })
        .catch((error) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: "AlertCircleIcon",
              variant: "danger",
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          });
        });
    };

    const copyLink = (link) => {
      const tempInput = document.createElement("input");
      tempInput.setAttribute("value", link);
      document.body.appendChild(tempInput);

      // Select the input element's content
      tempInput.select();
      tempInput.setSelectionRange(0, 99999); // For mobile devices

      // Copy the selected content to the clipboard
      document.execCommand("copy");

      // Remove the temporary input
      document.body.removeChild(tempInput);
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: `Success`,
          icon: "AlertCircleIcon",
          variant: "success",
          text: "Link copied to clipboard!",
          closeOnClick: false,
          timeout: false,
        },
      });
    };
    return {
      packages,
      tabs,
      copyLink,
      tab,
    };
  },
};
</script>

<style lang="scss">
.tab-card .nav-tabs {
  text-transform: capitalize;
}

.case-badge {
  border-radius: 4px;
  font-size: 14px;
  background: rgba(37, 99, 235, 0.12);
  color: #404966;
  line-height: 18px;
  height: 26px;
}

.price {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: #31609d;
  margin-top: 6px;
}
</style>