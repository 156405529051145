<template>
  <validation-provider :rules="rules" :name="name" v-slot="validationContext">
    <b-form-group :label="label" :label-for="id" class="date-input">
      <b-form-datepicker
        :id="id"
        v-model="values"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        :name="name"
        :placeholder="placeholder"
        :state="getValidationState(validationContext)"
        :aria-describedby="describedby"
        @input="changeValue"
      />
      <b-form-invalid-feedback :id="describedby">{{
        validationContext.errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
  
  <script>
import { ref, computed } from "@vue/composition-api";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    rules: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    describedby: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    const values = props.modelValue;

    const changeValue = (event) => {
      emit("input", event);
    };

    return {
      getValidationState,
      changeValue,
      values,
    };
  },
};
</script>